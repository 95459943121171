import React, { useEffect, useMemo, useState } from 'react'

import * as styles from './crudItems.module.scss'
import { Input, DateInput, Dropdown, Toggle, ToggleInfo } from '../form/fields'
import { formatUsDate, parseDate } from '../../../common/utils/functions'
import { DropdownWrapper } from './dropdownWrapper'
import { ToolTypes } from '../../../types'

interface activeProps {
   Id: number | string
   Title: string
   ToolTypeDesc: string
   Quantity: number
   StatusDesc: string
   Description: string
   ModelNumber: number | string
   SerialNumber: number | string
   Barcode: number | string
   Category: number | string
   CurrentLocation: string
   DefaultLocation: string
   PurchasePrice: number | string
   WebSiteURL: string
   Vendor: string
   QuantityEnabled: boolean
   Manufacturer: string
   ToolType: number
}

interface Props {
   activeValue: activeProps
   updateForm: (string, unknown, payload?: any) => void
   itemForm: any
   settings: any
   updateFormSelect: (payload: object) => void
   isQuantityOrConsumableToolType
   conditions: object[]
   handleWorkflowAction: (workflowOption: string) => void
   isEmployeeUser: boolean
}

export const OtherDetails = ({
   activeValue,
   itemForm,
   updateForm,
   updateFormSelect,
   settings,
   isQuantityOrConsumableToolType,
   conditions,
   handleWorkflowAction,
   isEmployeeUser,
}: Props): JSX.Element => {
   const { Id } = activeValue

   const {
      CustomDate1Enabled,
      CustomDate1Label,
      CustomDate2Enabled,
      CustomDate2Label,
      CustomText1Enabled,
      CustomText1Label,
      CustomText2Enabled,
      CustomText2Label,
      CustomURL1Enabled,
      CustomURL1Label,
      CustomURL2Enabled,
      CustomURL2Label,
      AuditEnabled,
   } = settings

   const [defaultCondition, setDefaultCondition] = useState(
      itemForm.LastAuditCondition
         ? {
              value: itemForm.LastAuditCondition,
              label: itemForm.LastAuditConditionDesc,
           }
         : null
   )

   useEffect(() => {
      updateFormSelect({
         ...itemForm,
         LastAuditCondition: defaultCondition?.value,
         LastAuditConditionDesc: defaultCondition?.label,
      })
   }, [defaultCondition])

   const handleDropdownChange = (
      name: { value: string | number; label: string },
      type: { name: string }
   ) => {
      if (name === null) return
      if (type?.name === 'LastAuditCondition') {
         const { value: rawValue, label } = name
         const value = parseInt(String(rawValue))
         return setDefaultCondition({ value, label })
      }
   }

   const handleToggleChange = (_id: string, isChecked: boolean) => {
      const action = isChecked ? 'Make Available' : 'Make Unavailable'
      handleWorkflowAction(action)
   }

   const displayAvailableToggle = useMemo(() => {
      return (
         itemForm?.ToolType !== ToolTypes.Kit &&
         !isEmployeeUser &&
         (itemForm?.Status === 0 || itemForm?.Status === 1)
      )
   }, [itemForm?.Status, itemForm?.ToolType])

   return (
      <>
         {displayAvailableToggle && (
            <div className={styles.toggleWrapper}>
               <div className={styles.toggle}>
                  <Toggle
                     id="available-toggle"
                     size="md"
                     isChecked={itemForm.IsAvailable}
                     onToggle={handleToggleChange}
                  />
               </div>
               <div className={styles.toggleInfo}>
                  <ToggleInfo
                     name={itemForm.IsAvailable ? 'Available' : 'Unavailable'}
                     value={itemForm.IsAvailable}
                     hideEnabledDisabled
                  />
               </div>
            </div>
         )}
         <div className={styles.itemColumns}>
            <div className={styles.columnOne}>
               <Input
                  id={`${Id}Vendor`}
                  label="Vendor"
                  onChange={(e) =>
                     updateForm('Vendor', e.target.value, itemForm)
                  }
                  value={itemForm.Vendor}
               />

               {!isQuantityOrConsumableToolType ? (
                  <>
                     <DateInput
                        id={`${Id}WarrantyDate`}
                        label="Warranty Date"
                        onChange={(e) => updateForm('WarrantyDate', e)}
                        selectedDate={
                           itemForm?.WarrantyDate
                              ? parseDate(itemForm.WarrantyDate)
                              : null
                        }
                     />
                  </>
               ) : (
                  <>
                     <Input
                        id={`${Id}WarrantyDate`}
                        label={'Warranty Date'}
                        value={formatUsDate(itemForm.WarrantyDate) || null}
                        disabled
                     />
                  </>
               )}

               <Input
                  id={`${Id}WebSiteURL`}
                  onChange={(e) =>
                     updateForm('WebSiteURL', e.target.value, itemForm)
                  }
                  label="Website Url"
                  value={itemForm.WebSiteURL}
               />

               <Input
                  id={`${Id}AttachmentUrl`}
                  onChange={(e) =>
                     updateForm('AttachmentUrl', e.target.value, itemForm)
                  }
                  label="Owners Manual Url"
                  value={itemForm.AttachmentUrl}
               />

               {AuditEnabled && (
                  <>
                     <DateInput
                        id={`${Id}LastAuditDate`}
                        label="Last Audit Date"
                        onChange={(e) => updateForm('LastAuditDate', e)}
                        selectedDate={
                           itemForm?.LastAuditDate
                              ? parseDate(itemForm.LastAuditDate)
                              : null
                        }
                     />

                     <DropdownWrapper action={setDefaultCondition}>
                        <Dropdown
                           defaultValue={defaultCondition}
                           isMulti={false}
                           id={`${Id}LastAuditCondition`}
                           name="LastAuditCondition"
                           onChange={handleDropdownChange}
                           options={conditions}
                           withWrappers={false}
                           placeholder="Last Audit Condition"
                           menuPlacement="auto"
                        />
                     </DropdownWrapper>
                  </>
               )}
            </div>
            <div className={styles.columnTwo}>
               {CustomText1Enabled && (
                  <Input
                     id={`${Id}customField1`}
                     onChange={(e) =>
                        updateForm('CustomText1Value', e.target.value, itemForm)
                     }
                     label={CustomText1Label}
                     value={itemForm.CustomText1Value || null}
                  />
               )}

               {CustomText2Enabled && (
                  <Input
                     id={`${Id}customField2`}
                     onChange={(e) =>
                        updateForm('CustomText2Value', e.target.value, itemForm)
                     }
                     label={CustomText2Label}
                     value={itemForm.CustomText2Value || null}
                  />
               )}

               {CustomDate1Enabled && (
                  <>
                     <DateInput
                        id={`${Id}customDate1`}
                        onChange={(e) => updateForm('CustomDate1Value', e)}
                        selectedDate={
                           itemForm?.CustomDate1Value
                              ? parseDate(itemForm.CustomDate1Value)
                              : null
                        }
                        label={CustomDate1Label}
                     />
                  </>
               )}

               {CustomDate2Enabled && (
                  <>
                     <DateInput
                        id={`${Id}customDate2`}
                        onChange={(e) =>
                           updateForm('CustomDate2Value', e, itemForm)
                        }
                        selectedDate={
                           itemForm?.CustomDate2Value
                              ? parseDate(itemForm.CustomDate2Value)
                              : null
                        }
                        label={CustomDate2Label}
                     />
                  </>
               )}

               {CustomURL1Enabled && (
                  <Input
                     id={`${Id}customUrl1`}
                     onChange={(e) =>
                        updateForm('CustomURL1Value', e.target.value, itemForm)
                     }
                     label={CustomURL1Label}
                     value={itemForm.CustomURL1Value || null}
                  />
               )}

               {CustomURL2Enabled && (
                  <Input
                     id={`${Id}customUrl2`}
                     onChange={(e) =>
                        updateForm('CustomURL2Value', e.target.value, itemForm)
                     }
                     label={CustomURL2Label}
                     value={itemForm.CustomURL2Value || null}
                  />
               )}
            </div>
         </div>
      </>
   )
}
