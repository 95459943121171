import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useNotifications } from '../../../hooks'
import { Button, LoadingOverlay, NoResults, SnackBar } from '..'
import NotificationItem from './notificationItem/notificationItem'
import { NotificationTypes, Severity } from '../../../types'

import { NotificationsListProps } from './notificationList.type'
import * as styles from './notificationList.module.scss'
import { SnackBarState } from '../snackBar/types'
import { defaultSnackbar } from '../../views/connections/constants'
import { closedSnackbarState } from '../snackBar/snackBar'

const NotificationList = ({
   hideAcceptDecline = false,
   hideDismiss = false,
   onUpdate,
   total,
   notificationType,
}: NotificationsListProps) => {
   const [currentPage, setCurrentPage] = useState<number>(1)
   const contentRef = useRef<HTMLDivElement | null>(null)
   const [snackbar, setSnackbar] = useState<SnackBarState>(defaultSnackbar)

   const {
      acceptReject,
      deleteGlobal,
      dismiss,
      isLastPage,
      isLoading,
      isLoadingMore,
      list,
      isDismissSuccess,
      isAcceptRejectSuccess,
      refetch: refreshNotifications,
   } = useNotifications({
      onChange: onUpdate,
      totalItems: total,
      notificationType,
      currentPage: currentPage,
   })

   useEffect(() => {
      if (contentRef.current) {
         contentRef.current.scrollTo(0, 0)
      }
   }, [])

   useEffect(() => {
      setCurrentPage(1)
   }, [notificationType])

   useEffect(() => {
      if (isDismissSuccess) {
         setSnackbar({
            message: 'The task was dismissed.',
            severity: Severity.SUCCESS,
         })
      }
   }, [isDismissSuccess])

   useEffect(() => {
      if (isAcceptRejectSuccess) {
         setSnackbar({
            message: 'The action was updated.',
            severity: Severity.SUCCESS,
         })
      }
   }, [isAcceptRejectSuccess])

   const isGlobal = useMemo(
      () => notificationType === NotificationTypes.Global,
      [notificationType]
   )

   const handleDismiss = (id: number) => {
      isGlobal ? deleteGlobal(id) : dismiss(id)
   }

   const handleAcceptDecline = (id: number, accept: boolean) =>
      acceptReject(id, accept)

   const onNoteSave = useCallback(() => {
      onUpdate()
      refreshNotifications()
   }, [])

   return (
      <div
         className={`${styles.wrapper} ${isLoading ? styles.noScroll : ''}`}
         ref={contentRef}
      >
         {isLoading && <LoadingOverlay positionAbsolute small />}
         <SnackBar
            open={
               snackbar.message !== '' && snackbar.severity !== Severity.INFO
            }
            message={snackbar.message}
            severity={snackbar.severity}
            resetMessageStateHandler={() => setSnackbar(closedSnackbarState)}
         />

         {list?.length > 0 &&
            list.map((item) => (
               <NotificationItem
                  key={item.Id}
                  onAcceptDecline={!hideAcceptDecline && handleAcceptDecline}
                  onDismiss={!hideDismiss && handleDismiss}
                  notification={item}
                  isGlobal={isGlobal}
                  refreshNotifications={onNoteSave}
               />
            ))}

         {list?.length > 0 && !isLastPage && (
            <div className={styles.loadMore}>
               <Button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  variant="tertiary"
                  small
                  loading={isLoadingMore}
               >
                  Load more
               </Button>
            </div>
         )}

         {!isLoading && !list.length && (
            <NoResults>
               <p>No tasks to show</p>
            </NoResults>
         )}
      </div>
   )
}

export default NotificationList
